@import '../../../../../assets/styles/settings/colors';

.header {
  .headerMain {
    display: flex;
    flex-direction: row;
    margin: 15px 0;
    align-items: center;

    .artworkWrapper {
      border-radius: 8px;
      width: 95px;
      height: 95px;
      background: $container_background;

      img {
        border-radius: inherit;
        width: inherit;
        height: inherit;
      }
    }

    .titleWrapper {
      margin-left: 15px;

      .name {
        display: block;
        font-weight: bold;
        font-size: 2em;
      }

      .curator {
        display: block;
        color: $tertiary_color;
      }

      .titleMeta {
        display: block;
        margin-top: 4px;
        color: $tertiary_color;
        font-size: 12px;
      }

      .playActions {
        .button {
          padding: 0 25px;
          height: 25px;
          animation: fadein 0.8s;
          border-color: $sidebar_color;
          background: $sidebar_color;
          color: $tertiary_color;
          border-radius: 4px;
          align-content: center;
          cursor: pointer;
          margin: 10px 10px 10px 0;

          &:hover {
            color: $accent_color;
            background: $indiscreet_hover_color;
          }

          .icon{
            font-size: 8px;
            padding-right: 5px;
          }
        }
      }
    }
  }

  .description {
    margin: 20px 0px;
  }
}
