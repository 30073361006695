.pageTitle {
  margin: 15px 0;
  cursor: default;
}

.pageTitle .contextHeading {
  text-transform: uppercase;
  color: #fe2851;
  font-size: 12px;
}

