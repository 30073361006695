@import '../../../assets/styles/settings/colors';

@mixin wrapTo($width) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: $width;
  display: block;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 15px 10px;
  transform: scale(1);
  transition: transform 0.3s;

  &.droppable {
    color: $accent_color;
  }

  .imageContainer {
    width: 100%;
    background: $container_background;
    border-radius: 8px;
    height: 0;
    padding-top: 100%;
    position: relative;

    .image {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      transition: opacity 0.2s;
    }
  }

  &:hover {
    transform: scale(1.025);
    .image {
      opacity: 0.8;
    }
  }

  &:active {
    transform: scale(0.975);
  }

  .descriptionContainer {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.65);
    color: $text_color_strong;
    font-weight: 600;

    > span {
      display: block;
      font-size: 1.15em;
      text-align: center;

      &.playlistName {
        font-weight: 400;
        margin: 10px 0 5px 0;
      }
    }
  }
}
