.sidebar {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-right: solid 2px #eeeeee;
  height: 100%;
  min-height: 500px;
  top: 66px;
  bottom: 0;
  width: 280px;
  padding: 0;
}

.sidebar .menus {
  flex: 1;
  overflow: auto;
  padding-bottom: 15px;
}

.sidebar .menus::-webkit-scrollbar {
  display: none;
}

.sidebar .menus .menu {
  background: #ffffff;
  padding: 10px 0;
  border: solid 1px #ffffff;
  margin-top: 15px;
}

.sidebar .menus .menu.dndSeeking {
  border: dashed 1px #fe8ea3;
}

.sidebar .menus .menu.dndHovered {
  background: #fe8ea3;
  color: #ffffff;
}

.sidebar .menus .menu h3 {
  margin: 0 0 10px;
  padding: 0 24px;
  color: #fe2851;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}

.sidebar .menus .menu ul {
  list-style-type: none;
  margin: 5px 0 0;
  padding: 0;
}

.sidebar .menus .menu ul li {
  color: #333333;
  font-weight: 500;
  margin: 0;
}

.sidebar .menus .menu ul li a {
  margin: 0;
  padding: 12px 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  border: solid 1px transparent;
}

.sidebar .menus .menu ul li a:hover {
  color: #333333;
}

.sidebar .menus .menu ul li a.dndSeeking {
  border: dashed 1px #fe8ea3;
}

.sidebar .menus .menu ul li a.dndHovered {
  background: #fe8ea3;
  color: #ffffff;
}

.sidebar .menus .menu ul li a.active {
  background: #f7f7f7;
  border: solid 1px #f7f7f7;
  color: #333333;
  font-weight: 500;
}

.sidebar .menus .footer {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #999999;
}

.sidebar .menus .footer a {
  color: gray;
}

.sidebar .menus .footer a:hover {
  color: #fe2851;
}

.sidebar .menus .footer .footnote {
  display: block;
  margin-top: 10px;
  font-size: 0.75em;
}

.sidebar .menus .footer .footnote i {
  font-size: 0.9em;
}

