.albumsGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.albumsGrid > div {
  margin: 15px 10px;
}

