@import '../../../../assets/styles/settings/colors';

.scrollWrapper {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 1px;
  }

  &::-webkit-scrollbar-track {
    background: $background_color;
    border-radius: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background: lighten($accent_color, 40%);
    border-radius: 1px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $background_color;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: lighten($accent_color, 25%);
  }
}

.trackGrid {
  display: grid;
  grid-auto-flow: column dense;
  grid-template-rows: auto auto auto auto;
  grid-gap: 0 20px;
  padding: 5px 0;
  justify-content: left;
  width: 0; // WTF, this shouldn't work but it makes it work and nothing else does. One day it may break, but until that day lets just completely forget that this is a thing...

  .track {
    width: 350px;
    height: 50px;

    &:nth-child(4n+1) > :first-child > :first-child:before  { // This is getting a little bit too "creative" now...
      display: none;
    }
  }
}
