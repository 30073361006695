.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container > div {
  margin: 15px 10px;
}

.artistList {
  width: 250px;
  height: 100%;
  border-right: solid 1px #f7f7f7;
  overflow: auto;
}

.artistList ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.artistList ul li {
  margin: 0;
  width: 100%;
}

.artistList ul li .artist {
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  align-items: center;
  font-weight: 500;
}

.artistList ul li .artistBacker {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  right: 0;
}

.artistList ul li .artistBacker:before {
  content: '';
  position: absolute;
  z-index: -2;
  left: 70px;
  right: 10px;
  top: 1px;
  height: 1px;
  display: block;
  background: rgba(220, 220, 220, 0.5);
}

.artistList ul li .artistBacker:after {
  content: '';
  background: none;
  transition: background 0.15s;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.artistList ul li:hover .artistBacker:after {
  background: #f7f7f7;
}

.artistList ul li:first-child .artistBacker:before {
  background: none;
}

.artistList ul li .activeArtist {
  color: #fe2851;
}

.artistList ul li .activeArtist .artistBacker:after {
  background: #f7f7f7;
}

.artistList ul li > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.artistList ul li .pictureWrapper {
  display: flex;
  width: 41px;
  height: 41px;
  margin-left: 15px;
  background: #cccccc;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.artistList ul li .pictureWrapper > img {
  border-radius: inherit;
}

.artistList ul li .pictureWrapper > span {
  color: #a6a6a6;
}

.artistList ul li .artistName {
  margin-left: 10px;
  font-size: 12px;
}

