@import "../../../../assets/styles/settings/colors";

.modal {
  width: 400px;
  height: 500px;
  z-index: 1999;
}

.lyricsSection {
  height: 455px;
}

.noMatch {
  color: $accent_color;
  font-weight: bold;
  text-align: center;
  padding: 20px;
}
