@import '../../../assets/styles/settings/colors';

@mixin wrapTo($width) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: $width;
  display: block;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 15px 10px;
  transform: scale(1);
  transition: transform 0.3s;

  &.droppable {
    color: $accent_color;
  }

  .imageContainer {
    width: 100%;
    background: $container_background;
    border-radius: 8px;
    height: 0;
    padding-top: 100%;
    position: relative;

    .image {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      transition: opacity 0.2s;
    }
  }

  &:hover {
    transform: scale(1.025);
    .image {
      opacity: 0.8;
    }
  }

  &:active {
    transform: scale(0.975);
  }

  .descriptionContainer {
    text-align: left;
    width: 100%;

    > span {
      display: block;
      @include wrapTo(100%);

      &.playlistName {
        font-weight: 400;
        margin: 10px 0 5px 0;
      }
    }
  }
}
