.header .headerMain {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  align-items: center;
}

.header .headerMain .artworkWrapper {
  border-radius: 8px;
  width: 95px;
  height: 95px;
  background: #f7f7f7;
}

.header .headerMain .artworkWrapper img {
  border-radius: inherit;
  width: inherit;
  height: inherit;
}

.header .headerMain .titleWrapper {
  margin-left: 15px;
}

.header .headerMain .titleWrapper .name {
  display: block;
  font-weight: bold;
  font-size: 2em;
}

.header .headerMain .titleWrapper .curator {
  display: block;
  color: #666666;
}

.header .headerMain .titleWrapper .titleMeta {
  display: block;
  margin-top: 4px;
  color: #666666;
  font-size: 12px;
}

.header .headerMain .titleWrapper .playActions .button {
  padding: 0 25px;
  height: 25px;
  animation: fadein 0.8s;
  border-color: #f9f9f9;
  background: #f9f9f9;
  color: #666666;
  border-radius: 4px;
  align-content: center;
  cursor: pointer;
  margin: 10px 10px 10px 0;
}

.header .headerMain .titleWrapper .playActions .button:hover {
  color: #fe2851;
  background: #f9f9f9;
}

.header .headerMain .titleWrapper .playActions .button .icon {
  font-size: 8px;
  padding-right: 5px;
}

.header .description {
  margin: 20px 0px;
}

