.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 15px 10px;
  transform: scale(1);
  transition: transform 0.3s;
}

.container.droppable {
  color: #fe2851;
}

.container .imageContainer {
  width: 100%;
  background: #f7f7f7;
  border-radius: 8px;
  height: 0;
  padding-top: 100%;
  position: relative;
}

.container .imageContainer .image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: opacity 0.2s;
}

.container:hover {
  transform: scale(1.025);
}

.container:hover .image {
  opacity: 0.8;
}

.container:active {
  transform: scale(0.975);
}

.container .descriptionContainer {
  text-align: left;
  width: 100%;
}

.container .descriptionContainer > span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.container .descriptionContainer > span.albumTitle {
  margin: 10px 0 5px 0;
  display: flex;
  width: 100%;
}

.container .descriptionContainer > span.albumTitle .albumName {
  flex: 1;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.container .descriptionContainer > span.albumTitle .explicit {
  background: #aaaaaa;
  color: #ffffff;
  display: flex;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  border-radius: 3px;
  font-size: 9px;
  margin-left: 5px;
  margin-top: 2px;
}

.container .descriptionContainer > span.artistName {
  font-weight: 300;
  color: #aaaaaa;
  margin: 0;
}

