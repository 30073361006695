.panel {
  display: flex;
  flex-direction: row;
}

.panel:not(:first-child) {
  margin-top: 30px;
}

.panel .playActions {
  display: flex;
  justify-content: space-between;
}

.panel .playActions .button {
  width: 48%;
  height: 30px;
  animation: fadein 0.8s;
  border-color: #f9f9f9;
  background: #f9f9f9;
  color: #666666;
  border-radius: 4px;
  align-content: center;
  cursor: pointer;
  line-height: 0px;
  margin: 10px 0;
}

.panel .playActions .button .icon {
  font-size: 9px;
  padding-right: 5px;
}

.panel .playActions .button:hover {
  color: #fe2851;
  background: #f9f9f9;
}

.panel .aside {
  width: 220px;
}

.panel .aside .artworkWrapper {
  border-radius: 8px;
  width: 220px;
  height: 220px;
  background: #f7f7f7;
}

.panel .aside .artworkWrapper img {
  border-radius: inherit;
  width: inherit;
  height: inherit;
}

.panel .aside .albumRuntimeDescription {
  display: block;
  text-align: center;
  color: #666666;
  font-size: 12px;
  margin-top: 5px;
}

.panel .main {
  margin-left: 25px;
  flex: 1;
}

.panel .main .title {
  display: block;
  font-weight: bold;
  font-size: 2em;
  clear: both;
  overflow: auto;
}

.panel .main .title > * {
  float: left;
}

.panel .main .title .explicit > span {
  background: #aaaaaa;
  color: #ffffff;
  display: flex;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  border-radius: 3px;
  font-size: 9px;
  margin-left: 5px;
  margin-top: 12px;
}

.panel .main .subtitle {
  display: block;
  color: #666666;
  margin-bottom: 10px;
}

.panel .main .subtitle > a:hover {
  color: #fe2851;
}

.panel .showCompleteContainer {
  margin-top: 10px;
}

.panel .showCompleteContainer > span {
  color: #fe2851;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 35px;
}

.panel .showCompleteContainer > span:hover {
  color: #bf0125;
}

