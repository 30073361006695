.searchGrid {
  display: flex;
  flex-wrap: wrap;
}

.searchGrid > div {
  margin: 15px 10px;
}

.searchArtistsGrid {
  display: flex;
  flex-wrap: wrap;
}

.searchArtistsGrid > * {
  margin: 15px 10px;
  width: 225px;
}

.searchError {
  margin-top: 20px;
  text-align: center;
}

.searchError .searchErrorTitle {
  font-size: 4em;
  display: block;
}

.searchError .searchErrorDetails {
  font-size: 1.5em;
  display: block;
  color: #999999;
}

