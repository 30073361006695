.settingsWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin-left: 21px;
  position: relative;
}

.settingsWrapper > i {
  color: #888888;
  font-size: 18px;
  cursor: pointer;
}

.settingsWrapper > .settingsControlWrapper {
  position: absolute;
  display: none;
  padding: 10px;
  z-index: 5;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  width: 170px;
  top: 50px;
  box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.25);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.settingsWrapper > .settingsControlWrapper h5 {
  font-size: 15px;
  color: #333333;
  margin-bottom: 10px;
}

.settingsWrapper > .settingsControlWrapper .radioWrapper {
  display: flex;
  color: #666666;
  font-size: 12px;
  margin-bottom: 5px;
}

.settingsWrapper > .settingsControlWrapper .radioWrapper input {
  margin-right: 10px;
}

.settingsWrapper:hover > .settingsControlWrapper {
  display: flex;
}

