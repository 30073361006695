.track {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.track .trackWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  font-size: 11px;
  min-height: 25px;
  position: relative;
  padding-left: 10px;
}

.track .trackWrapper > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.track .trackBacker {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  right: 0;
}

.track .trackBacker:before {
  content: '';
  position: absolute;
  z-index: -2;
  left: 70px;
  right: 10px;
  top: 1px;
  height: 1px;
  display: block;
  background: rgba(220, 220, 220, 0.5);
}

.track .trackBacker:after {
  content: '';
  background: none;
  transition: background 0.15s;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 5px;
}

.track:hover .trackBacker:after {
  background: #f7f7f7;
}

.track:first-child .trackBacker:before {
  background: none;
}

.track .trackInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.track .trackInfo.buffering {
  margin: 0;
}

.track .trackInfo > span {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  color: #aaaaaa;
}

.track .trackInfo .trackTitle {
  font-size: 12px;
  color: #333333;
  display: flex;
}

.track .trackInfo .trackTitle .trackName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.track .trackInfo .trackTitle .explicit {
  background: #aaaaaa;
  color: #ffffff;
  display: flex;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  border-radius: 3px;
  font-size: 9px;
  margin-left: 5px;
}

.track .trackRightSide {
  width: 60px;
  align-items: center;
  color: #666666;
}

.track .trackRightSide .trackActions {
  display: none;
  color: #fe2851;
  z-index: 100;
}

.track .trackRightSide .trackActions > * {
  padding: 6px 8px;
}

.track .trackRightSide .trackActions:hover {
  opacity: 0.5;
}

.track:hover .trackRightSide .trackDuration {
  display: none;
}

.track:hover .trackRightSide .trackActions {
  display: inline;
}

.track.disabledTrack {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.track.disabledTrack .trackInfo {
  opacity: 0.5;
}

.track.disabledTrack:hover .trackBacker:after {
  background: none;
}

