.curatorHeader {
  background-color: #cccccc;
  background-size: cover !important;
  background-position: top center !important;
  margin: 0 -1.5em;
  height: 275px;
  position: relative;
}

.curatorHeader .curatorHeaderContainer {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 75%, white 100%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.curatorHeader .curatorHeaderContainer .curatorHeaderPicture {
  width: 175px;
  height: 175px;
  background-color: #ffffff;
  background-size: cover !important;
  background-position: top center !important;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5%;
}

.playlistGrid {
  display: flex;
  flex-wrap: wrap;
}

.playlistGrid > div {
  margin: 15px 10px;
}

