@import "../../../../assets/styles/settings/colors";

.track {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .trackWrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    font-size: 11px;
    min-height: 25px;
    position: relative;
    padding-left: 10px;

    > span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .trackBacker {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    right: 0;

    &:before {
      content: '';
      position: absolute;
      z-index: -2;
      left: 70px;
      right: 10px;
      top: 1px;
      height: 1px;
      display: block;
      background: $track_background_color;
    }
  }

  .trackBacker:after {
    content: '';
    background: none;
    transition: background 0.15s;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 5px;
  }

  &:hover .trackBacker:after {
    background: $container_background;
  }

  &:first-child .trackBacker:before {
    background: none;
  }

  .trackInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.buffering {
      margin: 0;
    }

    > span {
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      color: $secondary_color;
    }

    .trackTitle {
      font-size: 12px;
      color: $text_color_strong;
      display: flex;

      .trackName {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .explicit {
        background: $secondary_color;
        color: $background_color;
        display: flex;
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        align-items: center;
        justify-content: center;
        font-weight: 300;
        border-radius: 3px;
        font-size: 9px;
        margin-left: 5px;
      }
    }
  }

  .trackRightSide {
    width: 60px;
    align-items: center;
    color: $tertiary_color;

    .trackActions {
      display: none;
      color: $accent_color;
      z-index: 100;

      > * {
        padding: 6px 8px;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &:hover {
    .trackRightSide {
      .trackDuration {
        display: none;
      }

      .trackActions {
        display: inline;
      }
    }
  }

  &.disabledTrack {
    pointer-events: none !important;
    cursor: not-allowed !important;

    .trackInfo {
      opacity: 0.5;
    }

    &:hover .trackBacker:after {
      background: none;
    }
  }
}
