.itemInfo {
  width: 220px;
  display: flex;
  flex-direction: row;
}

.itemInfo .artwork {
  width: 70px;
  padding: 0 5px;
}

.itemInfo .artwork .artworkWrapper {
  width: 60px;
  height: 60px;
  background: #aaaaaa;
  border-radius: 3px;
}

.itemInfo .artwork .artworkWrapper > img {
  width: inherit;
  height: inherit;
  border-radius: inherit;
}

.itemInfo .description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.itemInfo .description h1,
.itemInfo .description h2,
.itemInfo .description h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 145px;
  display: block;
}

.itemInfo .description h1 {
  font-size: 15px;
  margin-bottom: 5px;
}

.itemInfo .description h2,
.itemInfo .description h3 {
  font-size: 12px;
  color: #333333;
  font-weight: 400;
}

.itemInfo .description h3 {
  margin-top: 3px;
  font-weight: 300;
}

