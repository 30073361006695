.modal {
  width: 400px;
  height: 500px;
  z-index: 1999;
}

.lyricsSection {
  height: 455px;
}

.noMatch {
  color: #fe2851;
  font-weight: bold;
  text-align: center;
  padding: 20px;
}

