.navSearch {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navSearch:hover .navSearchWrapper input,
.navSearch.active .navSearchWrapper input {
  border-bottom-right-radius: 0;
}

.navSearch .navSearchWrapper {
  position: relative;
}

.navSearch .navSearchWrapper input,
.navSearch .navSearchWrapper button {
  -webkit-appearance: none;
  outline: none;
  padding: 8px 15px;
  text-align: center;
  border: solid 1px #eeeeee;
  color: #666666;
  font-size: 11px;
  line-height: 15px;
  background: #ffffff;
  border-right-width: 0;
}

.navSearch .navSearchWrapper input:first-child,
.navSearch .navSearchWrapper button:first-child {
  border-radius: 5px 0 0 5px;
}

.navSearch .navSearchWrapper input:last-child,
.navSearch .navSearchWrapper button:last-child {
  border-radius: 0 5px 5px 0;
  border-right-width: 1px;
}

.navSearch .navSearchWrapper input:hover,
.navSearch .navSearchWrapper input:active,
.navSearch .navSearchWrapper inputfocus,
.navSearch .navSearchWrapper button:hover,
.navSearch .navSearchWrapper button:active,
.navSearch .navSearchWrapper buttonfocus {
  color: #333333;
}

.navSearch .navSearchWrapper button:hover {
  background: #f9f9f9;
}

.navSearch .navSearchWrapper .results {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background: #ffffff;
  width: 320px;
  overflow: hidden;
  max-height: 70vh;
  box-shadow: 0 8px 36px -10px rgba(150, 150, 150, 0.75);
  border-radius: 6px 0 6px 6px;
  margin-right: 1px;
  text-align: left;
  flex-direction: column;
  z-index: 3100;
}

.navSearch .navSearchWrapper .results.show {
  display: flex;
}

.navSearch .navSearchWrapper .results .resultsContainer {
  overflow: auto;
}

.navSearch .navSearchWrapper .results .resultsContainer .empty {
  text-align: center;
  padding: 20px 0;
}

.navSearch .navSearchWrapper .results .resultsContainer .empty i {
  display: block;
  font-size: 1.5em;
  color: #b3b3b3;
  margin-bottom: 10px;
}

.navSearch .navSearchWrapper .results .resultsContainer .section:not(:first-child) {
  margin-top: 10px;
}

.navSearch .navSearchWrapper .results .resultsContainer .section .title {
  background: #eeeeee;
  color: #333333;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px 10px;
  font-size: 12px;
}

.navSearch .navSearchWrapper .results .resultsContainer .result {
  position: relative;
  padding-right: 10px;
  cursor: pointer;
}

.navSearch .navSearchWrapper .results .resultsContainer .result:hover {
  background: #f9f9f9;
  color: #fe2851;
}

.navSearch .navSearchWrapper .results .resultsContainer .result.song,
.navSearch .navSearchWrapper .results .resultsContainer .result.album,
.navSearch .navSearchWrapper .results .resultsContainer .result.playlist {
  display: flex;
  flex-direction: row;
}

.navSearch .navSearchWrapper .results .resultsContainer .result.song .detailsContainer,
.navSearch .navSearchWrapper .results .resultsContainer .result.album .detailsContainer,
.navSearch .navSearchWrapper .results .resultsContainer .result.playlist .detailsContainer {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  flex-direction: column;
}

.navSearch .navSearchWrapper .results .resultsContainer .result.song .detailsContainer .infos,
.navSearch .navSearchWrapper .results .resultsContainer .result.album .detailsContainer .infos,
.navSearch .navSearchWrapper .results .resultsContainer .result.playlist .detailsContainer .infos {
  font-size: 80%;
}

.navSearch .navSearchWrapper .results .resultsContainer .result.song .artwork,
.navSearch .navSearchWrapper .results .resultsContainer .result.album .artwork,
.navSearch .navSearchWrapper .results .resultsContainer .result.playlist .artwork {
  margin: 7px 10px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.8);
  width: 30px;
  height: 30px;
  border-radius: 3px;
}

.navSearch .navSearchWrapper .results .resultsContainer .result.song .artwork .catalogIndicator,
.navSearch .navSearchWrapper .results .resultsContainer .result.album .artwork .catalogIndicator,
.navSearch .navSearchWrapper .results .resultsContainer .result.playlist .artwork .catalogIndicator {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.8);
  border-top-right-radius: 3px;
  bottom: 0;
  left: 0;
  z-index: 310;
}

.navSearch .navSearchWrapper .results .resultsContainer .result.song .artwork .catalogIndicator > i,
.navSearch .navSearchWrapper .results .resultsContainer .result.album .artwork .catalogIndicator > i,
.navSearch .navSearchWrapper .results .resultsContainer .result.playlist .artwork .catalogIndicator > i {
  font-size: 10px;
  color: #fe2851;
  margin-left: 1px;
  margin-bottom: 1px;
  z-index: 320;
}

.navSearch .navSearchWrapper .results .resultsContainer .result.artist {
  padding: 10px;
}

.navSearch .navSearchWrapper .results .resultsContainer .result.artist > i {
  font-size: 10px;
  color: #fe2851;
  padding: 0 4px;
}

.navSearch .navSearchWrapper .results .resultsContainer .result.album span,
.navSearch .navSearchWrapper .results .resultsContainer .result.album .artwork,
.navSearch .navSearchWrapper .results .resultsContainer .result.playlist span,
.navSearch .navSearchWrapper .results .resultsContainer .result.playlist .artwork {
  align-self: center;
}

@supports (-webkit-backdrop-filter: blur(10px)) {
  .navSearch .navSearchWrapper .results {
    background: rgba(255, 255, 255, 0.85);
    -webkit-backdrop-filter: blur(10px) saturate(180%);
  }

  .navSearch .navSearchWrapper .results .resultsContainer .section .title {
    background: rgba(255, 255, 255, 0.5);
  }

  .navSearch .navSearchWrapper .results .resultsContainer .section .result:hover {
    background: rgba(249, 249, 249, 0.7);
    color: #fe2851;
  }
}

