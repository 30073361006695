.playlist {
  padding: 10px 3px;
  border-bottom: 1px solid #cccccc;
  cursor: pointer;
}

.playlist:hover {
  background: #eeeeee;
}

