@import "../../../../assets/styles/settings/colors";

.navSearch {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover, &.active {
    .navSearchWrapper {
      input {
        border-bottom-right-radius: 0;
      }
    }
  }

  .navSearchWrapper {
    position: relative;

    input, button {
      -webkit-appearance: none;
      outline: none;
      padding: 8px 15px;
      text-align: center;
      border: solid 1px $background_tint_color;
      color: $text_color;
      font-size: 11px;
      line-height: 15px;
      background: $background_color;
      border-right-width: 0;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
        border-right-width: 1px;
      }

      &:hover, &:active, &focus {
        color: $text_color_strong;
      }
    }

    button:hover {
      background: $indiscreet_hover_color;
    }

    .results {
      display: none;

      position: absolute;
      top: 100%;
      right: 0;
      background: $background_color;
      width: 320px;
      overflow: hidden;
      max-height: 70vh;
      box-shadow: 0 8px 36px -10px rgba(150, 150, 150, 0.75);
      border-radius: 6px 0 6px 6px;
      margin-right: 1px;
      text-align: left;
      flex-direction: column;
      z-index: 3100;

      &.show {
        display: flex;
      }

      .resultsContainer {
        overflow: auto;

        .empty {
          text-align: center;
          padding: 20px 0;

          i {
            display: block;
            font-size: 1.5em;
            color: mix($text_color, $background_color, 50%);
            margin-bottom: 10px;
          }
        }

        .section {
          &:not(:first-child) {
            margin-top: 10px;
          }

          .title {
            background: $background_tint_color;
            color: $text_color_strong;
            text-transform: uppercase;
            font-weight: bold;
            padding: 12px 10px;
            font-size: 12px;
          }
        }

        .result {
          position: relative;
          padding-right: 10px;
          cursor: pointer;

          &:hover {
            background: $indiscreet_hover_color;
            color: $accent_color
          }

          &.song, &.album, &.playlist {
            display: flex;
            flex-direction: row;

            .detailsContainer {
              display: flex;
              justify-content: center;
              white-space: nowrap;
              overflow: hidden;
              flex-direction: column;

              .infos {
                font-size: 80%;
              }
            }

            .artwork {
              margin: 7px 10px;
              flex-shrink: 0;
              position: relative;
              overflow: hidden;
              background: rgba(255, 255, 255, 0.8);
              width: 30px;
              height: 30px;
              border-radius: 3px;

              .catalogIndicator {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 12px;
                height: 12px;
                background: rgba(255, 255, 255, 0.8);
                border-top-right-radius: 3px;
                bottom: 0;
                left: 0;
                z-index: 310;

                > i {
                  font-size: 10px;
                  color: $accent_color;
                  margin-left: 1px;
                  margin-bottom: 1px;
                  z-index: 320;
                }
              }
            }
          }

          &.artist {
            padding: 10px;

            > i {
              font-size: 10px;
              color: $accent_color;
              padding: 0 4px;
            }
          }

          &.album, &.playlist {
            span, .artwork {
              align-self: center;
            }
          }
        }
      }

      @supports (-webkit-backdrop-filter: blur(10px)) {
        background: rgba($background_color, 0.85);
        -webkit-backdrop-filter: blur(10px) saturate(180%);

        .resultsContainer {
          .section {
            .title {
              background: rgba($background_color, 0.5);
            }

            .result:hover {
              background: rgba($indiscreet_hover_color, 0.7);
              color: $accent_color
            }
          }
        }
      }
    }
  }
}
