@import 'settings/colors';

body {
  min-height: 100vh;
  margin: 0;
  background: $background_color;
  color: $text_color_strong;
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, ' Helvetica Neue', 'Roboto', sans-serif;
}

* {
  outline: none !important;
  box-sizing: border-box;
}

div, span {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}

a {
  color: rgb(105, 105, 105);
  text-decoration: none;
  transition: color 0.1s;
  cursor: pointer;
}

a:hover {
  color: #fe2851;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

button {
  -webkit-appearance: none;
  background: none;
  border: solid 1px #fe2851;
  padding: 10px 15px;
  color: #fe2851;
  font-size: 11px;
  cursor: pointer;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
}

:global {
  #app-root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  #main-wrapper {
    display: flex;
    flex: 1;
    width: 100%;
    overflow: auto;

    > aside {
      overflow-y: auto;
      flex-shrink: 0;
    }

    #main-content {
      position: relative;
      display: flex;
      flex: 1;
    }
  }

  @import 'vendor/react-contextmenu';
  @import 'vendor/github-fork-ribbon-css';
  @import '~react-s-alert/dist/s-alert-default';
  @import '~react-s-alert/dist/s-alert-css-effects/slide';
  @import 'vendor/toaster';
  @import 'vendor/contextMenu';
}

@import 'musicon';
