@import "../../../assets/styles/settings/colors";

.navigationBar {
  height: 65px;
  background: $background_color;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 300;
  width: 100%;
  display: flex;
  padding-left: 1.5em;
  padding-right: 1.5em;

  .brand {
    font-size: 20px;
    margin: 0;
    display: flex;

    a {
      color: $brand_color;
      height: 100%;
      align-items: center;
      margin: auto 6px auto 0;
      display: flex;

      &:hover {
        color: $accent_color;
      }

      .icon {
        margin-top: 4px;
      }

      .name {
        margin-left: 10px;
      }
    }
  }
}
