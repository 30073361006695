.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 320px;
  height: 400px;
  margin-top: -200px;
  margin-left: -137.5px;
  z-index: 2000;
  overflow: hidden;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 8px 36px -10px rgba(150, 150, 150, 0.75);
  animation: fadein 0.1s;
  display: flex;
  flex-direction: column;
}

.header {
  height: 45px;
  display: flex;
  flex-direction: row;
  background: #fe2851;
  color: #ffffff;
  cursor: pointer;
  padding: 0 15px;
  flex-shrink: 0;
  flex-grow: 0;
}

.header > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.header > div.title {
  flex: 1;
  font-weight: 600;
  font-size: 16px;
}

.header > div.icons {
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 20px;
}

.header > div.icons:hover {
  opacity: 0.8;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.QueueList {
  position: relative;
  width: 400px;
  padding: 0;
}

.queueItem {
  display: flex;
  width: 100%;
  height: 54px;
  margin-top: 1px;
  border-bottom: 1px solid #efefef;
  background: #ffffff;
  color: #333333;
  z-index: 10000;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.queueItem.played {
  color: #999999;
}

.queueItem.playing {
  color: #fe2851;
  font-weight: 500;
}

.queueItem > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.queueItem .notSortable {
  cursor: default;
}

.queueItem .albumArtwork {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 3px;
  padding-left: 10px;
}

.queueItem .albumArtwork .artworkWrapper {
  border-radius: 3px;
  width: 40px;
  height: 40px;
  background: #fafafa;
}

.queueItem .albumArtwork .artworkWrapper img {
  border-radius: inherit;
  width: inherit;
  height: inherit;
}

.queueItem .queueItemInfo {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
}

.queueItem .queueItemInfo > span {
  display: block;
  width: 100%;
  margin-left: 15px;
}

.queueItem .queueItemInfo > span.title {
  margin-top: 5px;
  font-size: 14px;
  line-height: 14px;
}

.queueItem .queueItemInfo > span.description {
  font-size: 12px;
  line-height: 12px;
  color: #aaaaaa;
}

.queueItem .removeButton > i {
  padding: 5px 5px;
  margin-right: 15px;
  margin-left: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: color 0.1s;
}

.queueItem:hover .removeButton i {
  color: #fe2851;
}

.sortableHelper {
  cursor: row-resize;
  z-index: 10001;
}

