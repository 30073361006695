.container {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 250;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadein 0.1s ease-out;
}

.container .modal {
  width: 70%;
  max-width: 900px;
  max-height: 80%;
  padding: 15px 15px;
  overflow: auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 8px 36px -10px rgba(150, 150, 150, 0.75);
  animation: fadein 0.1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

