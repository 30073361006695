@import '../../../../../assets/styles/settings/colors';

.itemInfo {
  width: 220px;
  display: flex;
  flex-direction: row;

  .artwork {
    width: 70px;
    padding: 0 5px;

    .artworkWrapper {
      width: 60px;
      height: 60px;
      background: $secondary_color;
      border-radius: 3px;

      > img {
        width: inherit;
        height: inherit;
        border-radius: inherit;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1, h2, h3 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 145px;
      display: block;
    }

    h1 {
      font-size: 15px;
      margin-bottom: 5px;
    }

    h2, h3 {
      font-size: 12px;
      color: $text_color_strong;
      font-weight: 400;
    }

    h3 {
      margin-top: 3px;
      font-weight: 300;
    }
  }
}
