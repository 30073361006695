.scrollWrapper {
  overflow: auto;
}

.scrollWrapper::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-radius: 1px;
}

.scrollWrapper::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 1px;
}

.scrollWrapper::-webkit-scrollbar-thumb {
  background: #fff3f5;
  border-radius: 1px;
  cursor: pointer;
}

.scrollWrapper::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

.scrollWrapper:hover::-webkit-scrollbar-thumb {
  background: #ffa7b8;
}

.trackGrid {
  display: grid;
  grid-auto-flow: column dense;
  grid-template-rows: auto auto auto auto;
  grid-gap: 0 20px;
  padding: 5px 0;
  justify-content: left;
  width: 0;
}

.trackGrid .track {
  width: 350px;
  height: 50px;
}

.trackGrid .track:nth-child(4n+1) > :first-child > :first-child:before {
  display: none;
}

