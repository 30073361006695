.artworkItemGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.artworkItemGrid > div {
  margin: 15px 10px;
}

