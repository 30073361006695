@import '../../../assets/styles/settings/colors';

.splashContainer {
  background: $splash_background_color
  linear-gradient(315deg, #cd3c5a, $splash_background_color);
  background-size: 100% 100%;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    margin-bottom: 5px;
    color: white;
    animation: fadein 0.4s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .brand h1 {
      font-size: 48pt;
      font-weight: 600;
    }
    .betaLabel {
      margin-left: 10px;
      margin-top: 22px;
      span {
        font-weight: 400;
        color: $accent_color;
        background: $background_color;
        border-radius: 3px;
        padding: 4px 10px;
      }
    }
  }
  .subheading {
    margin-bottom: 30px;
    font-size: 16pt;
    font-weight: 400;
    color: white;
    animation: fadein 0.6s;
    width: 100%;
    text-align: center;
  }
  .accountCaption {
    color: $background_color;
    max-width: 630px;
    text-align: center;
    width: 50%;
    animation: fadein 0.8s;
  }
  .secureConnection {
    margin-top: 10px;
    color: $background_color;
    animation: fadein 0.8s;
    font-style: italic;
    font-size: 8pt;
  }
  hr {
    width: 100px;
    border: none;
    height: 2px;
    background: $background_color;
    opacity: 0.4;
    animation: fadein 0.8s;
    animation: growin 0.2s;
  }
  .button {
    animation: fadein 0.8s;
    border-color: $background_color;
    color: $background_color;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    padding: 10px 20px;

    &.browseButton {
      margin-top: 30px;
    }
  }
  .button:hover {
    background: $background_color;
    color: $accent_color;
  }
  .disclaimer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: $background_color;
    font-size: 11px;
    border: solid 1px $background_color;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    * {
      box-sizing: content-box;
    }
    .disclaimerIcon {
      font-size: 12px;
      padding: 8px 8px;
      background: $background_color;
      display: flex;
      flex: 1;
      align-items: center;
      > span {
        color: $background_color;
        background: $accent_color;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
      }
    }
    .disclaimerText {
      margin-right: 10px;
      width: 320px;
      padding: 5px 10px;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes growin {
  from {
    width: 0;
  }
  to {
    width: 100px;
  }
}
