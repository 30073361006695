.splashContainer {
  background: #fb453f linear-gradient(315deg, #cd3c5a, #fb453f);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.splashContainer .title {
  margin-bottom: 5px;
  color: white;
  animation: fadein 0.4s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.splashContainer .title .brand h1 {
  font-size: 48pt;
  font-weight: 600;
}

.splashContainer .title .betaLabel {
  margin-left: 10px;
  margin-top: 22px;
}

.splashContainer .title .betaLabel span {
  font-weight: 400;
  color: #fe2851;
  background: #ffffff;
  border-radius: 3px;
  padding: 4px 10px;
}

.splashContainer .subheading {
  margin-bottom: 30px;
  font-size: 16pt;
  font-weight: 400;
  color: white;
  animation: fadein 0.6s;
  width: 100%;
  text-align: center;
}

.splashContainer .accountCaption {
  color: #ffffff;
  max-width: 630px;
  text-align: center;
  width: 50%;
  animation: fadein 0.8s;
}

.splashContainer .secureConnection {
  margin-top: 10px;
  color: #ffffff;
  animation: fadein 0.8s;
  font-style: italic;
  font-size: 8pt;
}

.splashContainer hr {
  width: 100px;
  border: none;
  height: 2px;
  background: #ffffff;
  opacity: 0.4;
  animation: fadein 0.8s;
  animation: growin 0.2s;
}

.splashContainer .button {
  animation: fadein 0.8s;
  border-color: #ffffff;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  padding: 10px 20px;
}

.splashContainer .button.browseButton {
  margin-top: 30px;
}

.splashContainer .button:hover {
  background: #ffffff;
  color: #fe2851;
}

.splashContainer .disclaimer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 11px;
  border: solid 1px #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}

.splashContainer .disclaimer * {
  box-sizing: content-box;
}

.splashContainer .disclaimer .disclaimerIcon {
  font-size: 12px;
  padding: 8px 8px;
  background: #ffffff;
  display: flex;
  flex: 1;
  align-items: center;
}

.splashContainer .disclaimer .disclaimerIcon > span {
  color: #ffffff;
  background: #fe2851;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.splashContainer .disclaimer .disclaimerText {
  margin-right: 10px;
  width: 320px;
  padding: 5px 10px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes growin {
  from {
    width: 0;
  }

  to {
    width: 100px;
  }
}

