@import '../settings/colors';

.react-contextmenu {
  background: $background_color;
  border: none;
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.35);

  @supports (-webkit-backdrop-filter: blur(7px)) {
    background: rgba($background_color, 0.90);
    -webkit-backdrop-filter: blur(7px) saturate(180%);
  }

  .react-contextmenu-item:not(.react-contextmenu-item--divider) {
    background-color: transparent;

    &:hover {
      background-color: $accent_color;
      color: $background_color;
    }
  }
}
