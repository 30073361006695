.authorizeWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin-left: 21px;
}

.authorizeWrapper span {
  color: #888888;
  font-size: 12px;
}

.authorizeWrapper span:hover {
  cursor: pointer;
}

