.choices {
  width: 100%;
  border-bottom: solid 2px #eeeeee;
}

.choices .selectionItem {
  padding: 10px 15px;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1em;
  font-weight: 500;
  border-bottom: solid 2px #eeeeee;
  margin-bottom: -2px;
}

.choices .selectionItem:first-child {
  border-top-left-radius: 5px;
}

.choices .selectionItem:last-child {
  border-top-right-radius: 5px;
}

.choices .selectionItem:hover {
  border-bottom: solid 2px #cccccc;
}

.choices .selectionItem.selected {
  border-bottom: solid 2px #fe2851;
  color: #fe2851;
}

