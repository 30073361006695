.navigationBar {
  height: 65px;
  background: #ffffff;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 300;
  width: 100%;
  display: flex;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.navigationBar .brand {
  font-size: 20px;
  margin: 0;
  display: flex;
}

.navigationBar .brand a {
  color: #222222;
  height: 100%;
  align-items: center;
  margin: auto 6px auto 0;
  display: flex;
}

.navigationBar .brand a:hover {
  color: #fe2851;
}

.navigationBar .brand a .icon {
  margin-top: 4px;
}

.navigationBar .brand a .name {
  margin-left: 10px;
}

