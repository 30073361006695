@import '../../../assets/styles/settings/colors';

.sidebar {
  display: flex;
  flex-direction: column;
  background: $background_color;
  border-right: solid 2px $background_tint_color;
  height: 100%;
  min-height: 500px;
  top: 66px;
  bottom: 0;
  width: 280px;
  padding: 0;

  .menus {
    flex: 1;
    overflow: auto;
    padding-bottom: 15px;

    &::-webkit-scrollbar {
      display: none;
    }

    .menu {
      background: $background_color;
      padding: 10px 0;
      border: solid 1px $background_color;
      margin-top: 15px;

      &.dndSeeking {
        border: dashed 1px lighten($accent_color, 20%);
      }

      &.dndHovered {
        background: lighten($accent_color, 20%);
        color: $background_color;
      }

      h3 {
        margin: 0 0 10px;
        padding: 0 24px;
        color: $accent_color;
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
      }

      ul {
        list-style-type: none;
        margin: 5px 0 0;
        padding: 0;

        li {
          color: $text_color_strong;
          font-weight: 500;
          margin: 0;

          a {
            margin: 0;
            padding: 12px 24px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            display: block;
            border: solid 1px transparent;

            &:hover {
              color: $text_color_strong;
            }

            &.dndSeeking {
              border: dashed 1px lighten($accent_color, 20%);
            }

            &.dndHovered {
              background: lighten($accent_color, 20%);
              color: $background_color;
            }

            &.active {
              background: $container_background;
              border: solid 1px $container_background;
              color: $text_color_strong;
              font-weight: 500;
            }
          }
        }
      }
    }

    .footer {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;
      color: lighten($tertiary_color, 20%);

      a {
        color: lighten($tertiary_color, 10%);

        &:hover {
          color: $accent_color;
        }
      }

      .footnote {
        display: block;
        margin-top: 10px;
        font-size: 0.75em;

        i {
          font-size: 0.9em;
        }
      }
    }
  }
}
