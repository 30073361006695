.albumHeading {
  margin-top: 20px;
}

.playlistHeading {
  margin-top: 10px;
}

.recommendationGroup {
  padding: 10px;
}

.recommendationGroup .personalRecommendationsTitle {
  color: #666666;
  margin-left: 5px;
}

.recommendationGroup .personalRecommendationsGrid {
  margin-top: 5px;
  display: grid;
  grid-auto-flow: column dense;
  grid-template-rows: auto auto;
  grid-gap: 0 10px;
  justify-content: left;
}

.recommendationGroup .personalRecommendationsGrid > * {
  margin: 5px 5px;
}

.scrollWrapper {
  overflow: scroll;
}

.scrollWrapper::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-radius: 1px;
}

.scrollWrapper::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 1px;
}

.scrollWrapper::-webkit-scrollbar-thumb {
  background: #fff3f5;
  border-radius: 1px;
  cursor: pointer;
}

.scrollWrapper::-webkit-scrollbar-thumb:hover {
  background: #fe2851;
}

.scrollWrapper:hover::-webkit-scrollbar-thumb {
  background: #ffa7b8;
}

.scrollWrapper::-webkit-scrollbar-corner {
  display: none;
}

.scrollGrid {
  display: grid;
  grid-auto-flow: column dense;
  grid-template-rows: auto;
  grid-gap: 0 10px;
  justify-content: left;
  padding: 5px 0;
  width: 0;
}

.scrollGrid.doubleRow {
  grid-template-rows: auto auto;
}

h4 {
  font-weight: lighter;
}

