.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 5px 10px;
  height: 61px;
}

.container.droppable {
  color: #fe2851;
}

.container .pictureWrapper {
  display: flex;
  width: 41px;
  height: 41px;
  background: #cccccc;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.container .pictureWrapper > img {
  border-radius: inherit;
}

.container .pictureWrapper > span {
  color: #a6a6a6;
}

.container .descriptionContainer {
  text-align: left;
  width: 100%;
  margin-left: 10px;
  padding-right: 15px;
}

.container .descriptionContainer > span {
  display: block;
  display: block;
  width: 100%;
  overflow: hidden;
  word-wrap: break-word;
}

.container .descriptionContainer > span.artistName {
  font-weight: 300;
  color: #aaaaaa;
  margin: 0;
}

.container:hover {
  transform: scale(1.025);
}

.container:hover .image {
  opacity: 0.8;
}

.container:active {
  transform: scale(0.975);
}

