@import '../../../assets/styles/settings/colors';

.container {
  position: absolute !important; // Forcefully define size
  width: 100% !important; // Forcefully define size
  height: 100% !important; // Forcefully define size
  margin: 0 !important; // Forcefully define size
  top: 0 !important; // Forcefully define size
  left: 0 !important; // Forcefully define size
  z-index: 250;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadein 0.1s ease-out;

  .modal {
    width: 70%;
    max-width: 900px;
    max-height: 80%;
    padding: 15px 15px;
    overflow: auto;
    background: $background_color;
    border-radius: 8px;
    box-shadow: 0 8px 36px -10px rgba(150, 150, 150, 0.75);
    animation: fadein 0.1s;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
