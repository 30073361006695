.player {
  background: #f7f7f7;
  padding: 20px 15px;
  margin-top: 14px;
}

.player .main-info {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.player .main-info > div.picture {
  border-radius: 4px;
}

.player .main-info > div.picture > .image {
  width: 60px;
  height: 60px;
  border-radius: inherit;
}

.player .main-info > div.track {
  flex: 1;
  padding-left: 10px;
}

.player .main-info > div.track .artistName,
.player .main-info > div.track .albumName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 160px;
  display: block;
  font-size: 12px;
}

.player .main-info > div.track .artistName.link:hover,
.player .main-info > div.track .albumName.link:hover {
  cursor: pointer;
  color: #fe2851;
}

.player .main-info > div.track .artistName {
  font-weight: bold;
}

.player .main-info > div.track .albumName {
  margin-top: 3px;
  font-weight: 300;
}

.player .main-info > div.track h1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 160px;
  display: block;
  font-size: 18px;
  margin-bottom: 6px;
}

.player .progressBarWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.player .progressBarWrapper span {
  font-size: 11px;
  color: #888888;
}

.player .progressBarWrapper .playbackTime {
  margin-right: 8px;
}

.player .progressBarWrapper .playbackDuration {
  margin-left: 8px;
}

.player .progress-bar {
  margin: 4px auto;
  width: 100%;
  height: 4px;
  outline: none;
  border-radius: 2px;
  background: linear-gradient(to right, #fe2851 0%, #fe2851 50%, white 50%, white 100%) no-repeat;
  cursor: pointer;
  -webkit-appearance: none;
}

.player .progress-bar::-webkit-slider-runnable-track {
  box-shadow: none;
  border: none;
  background: transparent;
  -webkit-appearance: none;
}

.player .progress-bar::-webkit-slider-thumb {
  height: 10px;
  width: 5px;
  border: 0;
  background: #666666;
  border-radius: 2px;
  -webkit-appearance: none;
}

.player .buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.player .buttons > span {
  margin: 0 10px;
  font-size: 20px;
  line-height: 24px;
  color: #111111;
  cursor: pointer;
}

.player .buttons > span.main {
  font-size: 24px;
}

.player .buttons > span.controls {
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  flex: 1;
  justify-content: center;
  color: #888888;
}

.player .buttons > span:hover {
  color: #666666;
}

.player .buttons > span:active i {
  transform: scale(0.95);
}

.player .buttons > span.enabled {
  color: #fe2851;
}

.player .buttons > span.enabled:hover {
  color: #f2012f;
}

.player .buttons > span.shuffle.one {
  position: relative;
}

.player .buttons > span.shuffle.one:after {
  content: '1';
  position: absolute;
  top: 8px;
  right: 9px;
  height: 14px;
  width: 9px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  background: #ffffff;
  border-radius: 2px;
  text-align: center;
}

.volumeControlWrapper {
  position: relative;
}

.volumeControlWrapper > i {
  position: absolute;
  z-index: 10;
  width: 100%;
  text-align: center;
  padding: 5px 0;
  margin-top: -5px;
}

.volumeControlWrapper > .volumeControlContainer {
  position: absolute;
  display: none;
  z-index: 5;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  width: 30px;
  height: 120px;
  bottom: -5px;
  box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.25);
  align-items: center;
}

.volumeControlWrapper:hover > .volumeControlContainer {
  display: block;
}

.volumeControlWrapper .volumeBarWrapper {
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.volumeControlWrapper .volumeBarWrapper .volumeBar {
  transform: rotate(-90deg);
  width: 80px;
}

