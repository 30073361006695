@font-face {
    font-family: 'musicon';
    src:  url('../fonts/musicon.eot?c6fkv');
    src:  url('../fonts/musicon.eot?c6fkv#iefix') format('embedded-opentype'),
    url('../fonts/musicon.ttf?c6fkv') format('truetype'),
    url('../fonts/musicon.woff?c6fkv') format('woff'),
    url('../fonts/musicon.svg?c6fkv#musicon') format('svg');
    font-weight: normal;
    font-style: normal;
}

:global {
    .musicon {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'musicon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .musicon-logo:before {
        content: "\e900";
    }
}

