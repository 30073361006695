@import '../../../assets/styles/settings/colors';

$spinner-size: 32px !default;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingAnimation {
  > div {
    display: flex;
    align-items: center;
    margin: 15px 10px;
    height: 20px;

    span {
      display: inline-block;
      align-self: center;
      bottom: 0;
      width: 3px;
      height: 5px;
      background: $accent_color;
      animation: audio-wave 0.5s infinite ease-in-out alternate;
      transform: translateZ(0);
      margin-left: 1px;

      &:nth-child(2) {
        animation-delay: 0.1s;
      }

      &:nth-child(3) {
        animation-delay: 0.2s;
      }

      &:nth-child(4) {
        animation-delay: 0.3s;
      }

      &:nth-child(5) {
        animation-delay: 0.4s;
      }
    }
  }

  @keyframes audio-wave {
    0% {
      height: 5px;
    }
    100% {
      height: 20px;
    }
  }
}
